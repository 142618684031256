import React from "react"
import PropTypes from "prop-types"
import FormField from "../form-field"
import styles from "./styles.module.css"

const TextField = ({ label, id, labelHidden, labelAfterInput, ...rest }) => (
  <FormField labelAfterInput={labelAfterInput}>
    <label htmlFor={id} className={labelHidden ? "offscreen" : ""}>
      {label}
    </label>
    <input id={id} name={id} {...rest} className={styles.input} />
  </FormField>
)

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}

TextField.defaultProps = {}

export default TextField
