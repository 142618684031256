import React from "react"
import PropTypes from "prop-types"
import FormField from "../form-field"
import styles from "./styles.module.css"
//import MultiSelect from "react-select"
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes"
import styled from "styled-components"

const Style = styled.div`
  color: #000;
  button {
    margin-top: 10px;
    width: 100% !important;
  }
`

export const MultiSelect = ({ label, id, options, ...rest }) => (
  <FormField>
    <label htmlFor={id} className={styles.label}>
      {label}
    </label>
    <Style>
      <ReactMultiSelectCheckboxes
        id={id}
        name={id}
        options={options}
        {...rest}
      />
    </Style>
  </FormField>
)

const StyledSelect = styled.select`
  width: 100%;
  background: #fff;
  color: #000;
  display: block;
  width: 100%;
  height: 32px;
  border: 2px solid #dadada;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 1.3rem;
`

export const Select = ({ label, id, options, ...rest }) => (
  <FormField>
    <label htmlFor={id} className={styles.label}>
      {label}
    </label>
    <Style>
      <StyledSelect
        id={id}
        name={id}
        options={options}
        styles={styles.select}
        defaultValue="Select..."
        {...rest}
      >
        {options.map((o, i) => (
          <option value={o.value} key={o.value + i}>
            {o.label}
          </option>
        ))}
      </StyledSelect>
    </Style>
  </FormField>
)

Select.propTypes = {}

Select.defaultProps = {}
