import React from "react"
import TextField from "./forms/text-field"
import { Select, MultiSelect } from "./forms/select"
import Button from "./forms/button"
import styled from "styled-components"
import cheerMapHeaderImg from "./header-img.svg"

const Header = styled.div`
  padding: 45px 52px 18px 52px;
  position: relative;
`
const Title = styled.div`
  text-align: center;
  font-size: 2.2rem;
  margin-top: 25px;
`

const Form = styled.form`
  margin-top: 45px;
`
const Description = styled.div`
  padding: 0px 8px;
  font-size: 1.6rem;
`
const Space = styled.div`
  height: 150px;
`

const ToShare = styled.div`
  text-align: center;
  padding: 20px 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
`
const ToDonate = styled.div`
  padding: 20px 0;
  font-size: 1.4rem;
  bottom: 0;
  line-height: 1.8rem;
`

const DonateButton = styled.a`
  width: 100%;
  background: #3f54a1;
  height: 32px;
  border-radius: 4px;
  display: block;
  line-height: 32px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
  text-decoration: none;
`

const Cheermap = styled.img`
  border: 1px solid #3f54a1;
`

const Feedback = styled.div`
  text-align: center;
  margin: 10px;
  a {
    color: #fff;
  }
`

class London extends React.Component {
  state = { image: null, zones: [], loading: false }
  submit = e => {
    e.preventDefault()
    const bib = e.currentTarget.elements.bib.value || "12345"
    const startTime = e.currentTarget.elements["start-time"].value || "10:00am"
    const paceMinutes = e.currentTarget.elements.minutes.value || "10"
    const paceSeconds = e.currentTarget.elements.seconds.value || "00"
    // const cheerZones = e.currentTarget.elements["cheer-zones"]
    const cheerZones = this.state.zones.sort((a, b) =>
      a.value <= b.value ? -1 : 1
    )
    let zones = ""
    for (var i = 0; i < cheerZones.length; i++) {
      zones = zones + cheerZones[i].value + "|"
    }
    zones = zones.slice(0, -1)
    this.setState({ loading: true })
    fetch(
      `https://us-central1-cheer-map.cloudfunctions.net/cheerMap?bib=${bib}&startTime=${startTime}&paceMinutes=${paceMinutes}&paceSeconds=${paceSeconds}&cheerZones=${zones}&map=london`
    )
      .then(r => r.text())
      .then(r => {
        this.setState({ image: r, loading: false })
      })
    return
  }
  render() {
    if (this.state.loading) {
      return (
        <div style={{ padding: "42px 22px", textAlign: "center" }}>
          Generating your Cheermap...
        </div>
      )
    } else if (this.state.image) {
      return (
        <div
          style={{
            margin: `0 auto`,
            maxWidth: "100%",
            width: "420px",
            padding: `20px 20px`,
            paddingTop: 0,
          }}
        >
          <Cheermap
            src={this.state.image}
            style={{
              width: "100%",
              maxWidth: 420,
            }}
          />
          <ToShare>
            <div style={{ fontSize: "1.6rem" }}>- To Share -</div>
            <div>
              <span>
                Tap and Hold on your cheermap to download it. Share as you would
                any image!
              </span>
            </div>
          </ToShare>
          <br />
          <br />
          <br />
          <ToDonate>
            <div>
              cheermap.run was created by a Boston Marathon qualifier & a Boston
              Marathon charity runner. Please consider a $5 donation to support
              their cause to beat cancer.
            </div>
            <DonateButton
              href="https://danafarber.jimmyfund.org/site/TR?px=1059024&fr_id=1110&pg=personal"
              target="_blank"
            >
              Donate to Cancer Research
            </DonateButton>
            <Feedback>
              <a href="https://forms.gle/BXypdr17MugWFZwE6" target="_blank">
                Leave us Feedback
              </a>
            </Feedback>
          </ToDonate>
        </div>
      )
    }
    return (
      <div
        style={{
          margin: `0 auto`,
          maxWidth: "100%",
          width: "420px",
          padding: `0px 30px`,
          paddingTop: 0,
          background: `url('${cheerMapHeaderImg}') no-repeat`,
          backgroundPosition: "0 25px",
          backgroundSize: "100% auto",
        }}
      >
        <Header>
          <Title>
            <div>London Marathon</div>
            <div>CHEER MAP</div>
          </Title>
        </Header>
        <Description>
          Enter all the fields to create your own custom Boston Marathon Cheer
          Map Graphic to share with your biggest fans!
        </Description>
        <Form onSubmit={this.submit}>
          <TextField id="bib" label="Enter Bib Number" required />
          <Select
            label="Start Time"
            id="start-time"
            required
            options={[
              { value: "10:20am", label: "3:30Hr Pace 10:20am" },
              { value: "10:30am", label: "4:30Hr Pace 10:30am" },
              { value: "10:40am", label: "5:00Hr Pace 10:40am" },
              { value: "10:50am", label: "6:00Hr Pace 10:50am" },
              { value: "11:00am", label: "7:00Hr Pace 11:00am" },
              { value: "9:10am", label: "WPA*" },
              { value: "9:25am", label: "Elite Women 9:25am" },
              { value: "10:10am", label: "Elite Men 10:10am" },
              { value: "9:05am", label: "Men’s Push Rim Wheelchair 9:05am" },
              { value: "9:05am", label: "Women’s Push Rim Wheelchair 9:05am" },
            ]}
          />
          <fieldset style={{ padding: 0, border: "none" }}>
            <legend style={{ padding: 0 }}>Estimated Pace min/mile</legend>
            <div style={{ display: "flex", width: "100%" }}>
              <TextField
                required
                id="minutes"
                label="Minutes"
                labelAfterInput
                type="number"
              />
              <TextField
                required
                id="seconds"
                label="Seconds"
                labelAfterInput
                type="number"
              />
            </div>
          </fieldset>
          <MultiSelect
            label={
              <>
                <div>Cheer Zones</div>
                <div>Select the areas you want displayed on your cheer map</div>
              </>
            }
            id="cheer-zones"
            onChange={d => this.setState({ zones: d })}
            options={[
              {
                value: "2.5:Woolwich (Thames Barrier)",
                label: "Woolwich (Thames Barrier) Mile 2.5",
              },
              { value: "6.5:Cutty Stark", label: "Cutty Stark Mile 6.5" },
              {
                value: "12.5:Tower Bridge",
                label: "Tower Bridge Mile 12.5",
              },
              {
                value: "15:Westerly Circus (Docklands)",
                label: "Westerly Circus (Docklands) Mile 15",
              },
              { value: "19:Canary Wharf", label: "Canary Wharf Mile 19" },
              {
                value: "23:Tower Hill",
                label: "Tower Hill Mile 23",
              },
              {
                value: "24:Blackfriars Underpass",
                label: "Blackfriars Underpass Mile 24",
              },
              { value: "25.5:Big Ben", label: "Big Ben Mile 25.5" },
              {
                value: "26.2:Finish",
                label: "Finish Mile 26.2",
              },
            ]}
          />
          <Button>Create Image</Button>
          <Space />
          <Feedback>
            <a href="https://forms.gle/BXypdr17MugWFZwE6" target="_blank">
              Leave us Feedback
            </a>
          </Feedback>
        </Form>
      </div>
    )
  }
}

export default London
