import React from "react"
import PropTypes from "prop-types"
import buttonStyles from "./styles.module.css"

const Button = ({ children, ...rest }) => (
  <button {...rest} className={buttonStyles.button}>
    {children}
  </button>
)

Button.propTypes = {}

Button.defaultProps = {}

export default Button
