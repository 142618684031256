import React from "react"
import { Link } from "gatsby"
import "./global.css"
import { createTheme, ThemeProvider } from "@vitalsource/vstui/themes"
import { cloud } from "@vitalsource/vstui/colors"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Boston from "../components/boston"
import London from "../components/london"
import styled from "styled-components"

const theme = createTheme({
  color: {
    primary: cloud,
    secondary: cloud,
  },
})

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <SEO
        title="CheerMap"
        keywords={[`marathon`, `cheermap`, `running`, `boston`, `london`]}
      />
      <Boston />
    </Layout>
  </ThemeProvider>
)

export default IndexPage
