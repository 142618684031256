import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledFormField = styled.div`
  margin-bottom: 17px;
  display: ${props => (props.labelAfterInput ? "flex" : "auto")};
  flex-direction: row-reverse;
  align-items: center;
  margin-top: ${props => (props.labelAfterInput ? "14px" : "")};
  * {
    margin-top: ${props => (props.labelAfterInput ? "0px" : "")} !important;
  }
  *:first-child {
    padding: ${props => (props.labelAfterInput ? "0 10px" : "")} !important;
  }
`

const FormField = ({ children, ...props }) => (
  <StyledFormField {...props}>{children}</StyledFormField>
)

FormField.propTypes = {}

FormField.defaultProps = {}

export default FormField
